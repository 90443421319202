import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c54727d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "update-password"
};
import { ref, computed, reactive } from 'vue';
import checkIcon from '../../../assets/images/login-check-icon.svg';
import closeIcon from '../../../assets/images/login-close-icon.svg';
import { userInfo as localUserInfo } from '../../../utils/globalData';
import { updatePassword } from '../../../api/user';
import { showSuccessToast } from 'vant';
import 'vant/es/toast/style';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();

    // 密码验证函数
    const passwordValidator = val => {
      return /^(?!.*\s).{6,20}$/.test(val);
    };
    // 确认密码验证函数
    const confirmPasswordValidator = val => {
      let flag = false;
      if (val === registerForm.password) {
        flag = true;
      }
      return flag;
    };

    // 重置密码 开始
    const registerRef = ref();
    const registerForm = reactive({
      oldPassword: '',
      password: '',
      confirmPassword: ''
    });
    const registerRules = {
      oldPassword: [{
        required: true,
        message: '请输入您的旧密码'
      }, {
        validator: passwordValidator,
        message: '6-20位 不能出现空格'
      }],
      password: [{
        required: true,
        message: '请输入新密码'
      }, {
        validator: passwordValidator,
        message: '6-20位 不能出现空格'
      }],
      confirmPassword: [{
        required: true,
        message: '请输入验证码'
      }, {
        validator: confirmPasswordValidator,
        message: '请再次确认密码，保持相同'
      }]
    };

    // 密码图标切换
    const passwordIcon = ref(closeIcon);
    const confirmPasswordIcon = ref(closeIcon);
    const isShowPassword = ref('password');
    const registerPasswordIcon = () => {
      if (passwordIcon.value === closeIcon) {
        isShowPassword.value = 'text';
        passwordIcon.value = checkIcon;
      } else {
        isShowPassword.value = 'password';
        passwordIcon.value = closeIcon;
      }
    };
    const isShowConfirmPassword = ref('password');
    const registerConfirmPasswordIcon = () => {
      if (confirmPasswordIcon.value === closeIcon) {
        isShowConfirmPassword.value = 'text';
        confirmPasswordIcon.value = checkIcon;
      } else {
        isShowConfirmPassword.value = 'password';
        confirmPasswordIcon.value = closeIcon;
      }
    };

    // 注册提交按钮样式切换
    const isRegisterSubmitEnabled = computed(() => {
      let flag = true;
      if (passwordValidator(registerForm.password) && confirmPasswordValidator(registerForm.confirmPassword) && passwordValidator(registerForm.oldPassword) && registerForm.confirmPassword === registerForm.password) {
        flag = false;
      }
      return flag;
    });

    // 重置密码表单提交
    const onResetPasswordSubmit = () => {
      const data = {
        // guid: localStorage.getItem('user_guid'),
        guid: localUserInfo.guid,
        old_password: registerForm.oldPassword,
        password: registerForm.password
      };
      updatePassword(data).then(() => {
        showSuccessToast({
          message: '密码修改成功',
          duration: 1000,
          onClose: () => {
            router.push('/setting');
          }
        });
      }).catch(() => {});
    };
    // 重置密码 结束

    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_form, {
        ref_key: "registerRef",
        ref: registerRef,
        class: "register-form",
        onSubmit: onResetPasswordSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          style: {
            "position": "relative"
          },
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            "error-message": "",
            autocomplete: "",
            maxlength: 20,
            modelValue: registerForm.oldPassword,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => registerForm.oldPassword = $event),
            name: "oldPassword",
            placeholder: "请输入旧密码",
            type: "text",
            rules: registerRules.oldPassword
          }, null, 8, ["modelValue", "rules"])]),
          _: 1
        }), _createVNode(_component_van_cell_group, {
          style: {
            "position": "relative"
          },
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            "error-message": "",
            autocomplete: "",
            maxlength: 20,
            modelValue: registerForm.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => registerForm.password = $event),
            name: "password",
            placeholder: "请输入新密码",
            type: isShowPassword.value,
            rules: registerRules.password
          }, null, 8, ["modelValue", "type", "rules"]), _createVNode(_component_van_icon, {
            class: "icon",
            name: passwordIcon.value,
            onClick: registerPasswordIcon
          }, null, 8, ["name"])]),
          _: 1
        }), _createVNode(_component_van_cell_group, {
          style: {
            "position": "relative"
          },
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            "error-message": "",
            autocomplete: "",
            maxlength: 20,
            modelValue: registerForm.confirmPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => registerForm.confirmPassword = $event),
            name: "confirmPassword",
            placeholder: "请再次确认密码，保持相同",
            type: isShowConfirmPassword.value,
            rules: registerRules.confirmPassword
          }, null, 8, ["modelValue", "type", "rules"]), _createVNode(_component_van_icon, {
            class: "icon",
            name: confirmPasswordIcon.value,
            onClick: registerConfirmPasswordIcon
          }, null, 8, ["name"])]),
          _: 1
        }), _createVNode(_component_van_button, {
          class: _normalizeClass([!isRegisterSubmitEnabled.value ? 'active-btn' : '']),
          disabled: isRegisterSubmitEnabled.value,
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交 ")]),
          _: 1
        }, 8, ["class", "disabled"])]),
        _: 1
      }, 512)]);
    };
  }
};